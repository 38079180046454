
import React, { useEffect } from 'react';
import './App.css';
import $ from 'jquery'; // Ensure jQuery is imported
import Modal from 'react-modal';
import image from './Designer.png';
import backgroundImage from './4871703.jpg';
import { useReducer } from 'react';
import Card from './Card';


function App() {
  const [showLetter, setShowLetter] = React.useState(2);

 
  useEffect(() => {
    if (showLetter) {

    $(document).ready(function () {
      $('.container').mouseenter(function () {
        $('.card').stop().animate({
          top: '-90px'
        }, 'slow');
      }).mouseleave(function () {
        $('.card').stop().animate({
          top: 0
        }, 'slow');
      });
    });
  }
    
  }, [showLetter]);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  
  function openModal() {
    setIsOpen(true);
    forceUpdate();

  }
  function onImageClick() {

    setShowLetter(0)

  }
  return (
    <div > 
      {showLetter == 2 && ( 
      <div class='OpeningPage' 
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', // or 'contain', '100% 100%', etc.
        width: '100%', // or specific width
        height: '100vh', // or specific height
      }}>
      <h1 style={{ fontFamily: 'Brush Script MT', color: 'white', fontSize: "10vh"}}> Happy 5th Anniversary</h1> 
      <img src={image}  width={250} height={250} alt="image" onClick={() => onImageClick()} />
      </div>
      )}

{showLetter == 0 && ( 

<Card setShowLetter={setShowLetter}/>
      )}
{showLetter== 1 && (
  <div class="letterPage">
<div class="container">  
<div class="valentines">
  <div              
 class="envelope"></div>
  <div onClick={openModal} class="front"></div>
  <div onClick={openModal} class="card">
  <div class="text">Happy <br></br> Anniversary <br></br> BB!</div>
  <div class="heart"></div>
  </div>
  <div class="hearts">
    <div class="one"></div>
    <div class="two"></div>
    <div class="three"></div>
    <div class="four"></div>
    <div class="five"></div>
  </div>
</div>
</div>
<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={true}
      >
        <div style={{         fontFamily: "'Dancing Script', cursive", // Love-themed font
 color: 'black'}} className='letter'>

       Happy 5th Anniversary bb, I hope you like this gift! I know you said all you want is a good card so I hope this counts. It did not come out as good as I wanted, but it took me a really long time so I hope you at least kind of like it. I can't believe we have been together for 5 years, it feels like just yesterday we met at KGS and we go to hangout at the condo, but at the same time it feels like I have known you my entire life. I can't even imagine how I lived life without you for 18 years, it still boggles my mind that someone I did not even talk to 6 years ago has become the most important person in my life. One little girl has become my life, my heart, my future, my world, I still cannot wrap my head around it. I cant imagine going about life without you. I have said this before but before you, life felt like nothing, I felt like I was just living for the sake of it with no reason, but after I met you life I had a reason to live life. You brought colour into my life and I will forever cherish that. In the early parts of our relationship I would always think how this one little girl can have such a hold and effect on me. It was at the moment I realized that I loved you, because how could I not love someone that effected me so much, someone that could make me not sleep at all, someone I would count the days to when I could see her again. It is crazy we have been together for 5 years and I wake up some days and still cannot believe I have a girlfriend. The fact that 2 strangers like us were practically made for eachother, the invisible string theory is just so strong for us. What are the chances that we could have met so many times in highschool, but life put us together at the perfect time, we were designed to be together. I often think to myself just how lucky I am to have you, you are literally perfect, although I dont express it often, you just are the perfect human being and everyday I am grateful that you are in my life. 
       You are an angel to me and I would give anything to make sure you are happy. I cannot wait to go into the future with you, buy a house, live together, go on as many vacations as we can before having kids, then show the kids that their mom and dad are crazy in love and perfect eachother, then we will grow old and spend every single second together because there is no one else I would rather spend it with. And I would always pray that I die first because I wouldnt know how i would live if you were to leave me first. When you sent me that post a couple years ago about the little things someones partner does that makes them happy and I thought of so many for you. I love when we are walking and you hook onto my hand, I love when you have a little smile when you are walking to my car when i am picking you up, I love the mischevious little smile you have when you come to ask me for something, the baby voice you have when you talk to me, when you sniff me all around my body like a little puppy, when you stand at the door and wave my goodbye, when you sing dumb little songs with a dumb little dance, the little dance you do after eating yummy food, your face when you try and act innocent after being dum,
       when you try acting like you froze on facetime but your hands are so shakey that its so obvious, there are so many more little things but we would be here all day if I continued. These little things always make my day and I dont know what I would do without them. I have never had much affection my whole life, but you changed that, you show me a type of affection that I am not used to so I dont always know how to respond, but just know even if I do not react on the outside, when you do that you heal the little boy in me that always wanted affection and attention growing up.
       There is this quote that I see and it always makes me think of you when I see it, it might sound a bit corny to you but I really love this quote <span style={{ fontStyle: 'italic', color: 'grey' }}>"I wish I could live life five times over. Then I’d be born in five different cities, and eat five different kinds of food, and have five different careers... and fall in love with the same person five times."</span> It basically means if I lived many different lives many things could change, the food I like, the things I do, my job, but one thing is for certain I know I would fall in love with same little girl every single time. In my life I did not really truly care about others because I always felt like no one ever put me first or was truly there for me, I feel like I was always betrayed by someone even when I put them first, but thank you for showing me how it feels to truly be put first, its a feeling I had never experienced before and it healed the little boy in me. The little boy in me finally found someone that would not be left alone and found someone that would put him first. Life before I met you was grey, you introduced colour into my life. When I feel down I am comforted by the fact that I still have you, when I feel like there is no one that is really there for me I will always think I still have Luckshe. Its crazy how I see you in everything. I see you in any show I watch or I go to the store and see a Coco and I am reminded of how you are so addicted you try putting both our phone numbers to get points. Everything will remind me of you and anytime I am able to be reminded of you it makes me happy. I dont pray or wish a lot, but everytime I do it is always about you. I will forever love this tiny, stubborn, dum, hyper little girl, that lights up everything around her and makes me feel things I never thought I could, she is like my little sun that I will orbit for the rest of my life.
       I know this is sounds corny and gay but this is just how I feel about you in my head. There is so much more I want to write but after that 40 slide slidshow you probably are a little tired, dont worry I will cuddle you and rub you to sleep.

       
       These recent events have made me realize that I cant constantly be focusing on our future and that I should also prioritize the presnent a lot more, so i will try my best to be more present and prioritize our present relationship rather than our future. I realized I have all these ambitions for our future, but I wouldn't mind living in a small house, living paycheck to paycheck, not being able to afford luxurys as long as it meant coming home to you, because you are my home, so what more could I ask for. I know we recently hit one of the darkest times of our relationship and I will never forgive myself for what I did, but just know I will do everything in my power to try and fix it, because I cannot live without you and seeing you hurt this much kills me inside
        I will be waiting for the day that you are able to take back what you said, about our relationship no longer being perfect, of it no longer being pure and it being tainted. I will do everything I can to make it so you are able to take it back, and the day you are able to take it back is the day when I will be the happiest man alive, I will forever wait for that day till the end of my life and I will forever love you and try my best to make you happy till the end of my life, that is a promise, because you are my life, and life is meaniningless if I cannot come home to you everyday or look forward to growing old with you, that is my one true goal in life and I will spend the rest of eternity trying to make you happy.
        Happy Anniversary bb, I love you with all my heart, and I will forever love you, because you are my home, you are my little sun that forever draws me in and forever mesmerized by your light. <h3>I love you forever</h3>

        <p>Here are some more quotes that I like and make me think about you</p>
        <p>---------------------------------------------------------------</p>
        <p style = {{color: "grey", fontSize: "15px"}}>"And if I could live life again I would repeat every mistake so long as it leads me back to you"</p>
        <p>---------------------------------------------------------------</p>
        <p style = {{color: "grey", fontSize: "15px"}}>"I like being alone but that is why I knew you were different because for the first time ever I wanted someone else's company more than my own"</p>
        <p>---------------------------------------------------------------</p>
        <p style = {{color: "grey", fontSize: "15px"}}> <b>This one is one of my favs because it just perfectly describe you</b> "She was like the sun... She attracted everything, forgave everything, illuminated everything, and spun everything around... I was just... happy to be spun around by her"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"For all the things my hands have held, the best by far is you"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"You will always be the one I search for in a room full of everyone else"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"Sometimes when my luck is running out I look at you and remember the blessings that fell into my life"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"I caught myself smiling and then I realized I was thinking about you"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"Even if the day we met would have never taken place, somehow, someway in another day, we would have met and fallen in love like the first day."</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"For you I wouldn't mind being the bird outside your window as long as my songs make you happy. I wouldn't mind being the moon if my light would bring you peace, or the rain lulling you to sleep, if I could carry you into magical dreams"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"Oh how sweet is time for allowing you and I to live in the same lifetime"</p>
        <p>---------------------------------------------------------------</p>

        <p style = {{color: "grey", fontSize: "15px"}}>"I aspire my grandchildren one day to say I want to find love like the one my grandparents have"</p>
        <p>---------------------------------------------------------------</p>
        <p style = {{color: "grey", fontSize: "15px"}}>"I have so many different dreams about a perfect life and they all have one thing in common you"</p>

        <p>---------------------------------------------------------------</p>
        <p style = {{color: "grey", fontSize: "15px"}}>"I may never find words beautiful enough to describe all that you mean to me but I will spend the rest of my life searching for them"</p>




        </div>
  
      </Modal>
      </div>
)}
</div>
  );
}

export default App;
