import React from 'react';
import "./Card.css";
import backgroundImage from './10282380.jpg';
import cardImage from './IMG_2379.JPG'
import Modal from 'react-modal';
import Pictures from './Pictures';


export default function Card({setShowLetter}) {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    function closeModal() {
      setIsOpen(false);
    }
    
    function openModal() {
      setIsOpen(true);  
    }

    const handleButtonClick = () => {
        setShowLetter(1);
      };
  return (
    <div class="pageContainer"
    style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', // or 'contain', '100% 100%', etc.
        width: '100%', // or specific width
        height: '100vh', // or specific height
      }}> 
      <img
        style={{
          backgroundSize: 'cover',
          width: '30%',
          height: '30vh',
          border: '5px solid pink', // Add a pink border
          borderRadius: '15px', // Rounded corners for a more aesthetic look
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional shadow for a 3D effect
        }}
        onClick={openModal}
        src={cardImage}
        alt=""
      />
      <span style={{ fontFamily: 'Brush Script MT', color: 'pink', fontSize: "10vh"}}>Throughout the years</span>


<Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={true}
      >
   <Pictures/>
  
      </Modal>


<button
  onClick={handleButtonClick}
  style={{
    background: 'linear-gradient(45deg, #ff69b4, #ff1493)', // Pink gradient
    color: 'white',
    border: 'none',
    borderRadius: '15px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    fontFamily: '"Brush Script MT", cursive', // Love-themed font
  }}
  onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
  onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
>
  Click me after Clicking the photo
</button>  </div>
  );
}
